import ContractsListView from '@/modules/contracts/views/ContractsListView'
import ContractsSignatureView from '@/modules/contracts/views/ContractsSignatureView'

export default [
  {
    path: '/contratos',
    name: 'Contracts',
    meta: {
      title: 'Lista de Contratos',
      rule: 'employee',
    },
    component: ContractsListView,
  },
  {
    path: '/FirmaContratos',
    name: 'ContractsSignature',
    meta: {
      title: 'Firma de Contratos',
      rule: 'employee',
    },
    component: ContractsSignatureView,
    props: true,
  },
]
