import Vue from 'vue'
import VueRouter from 'vue-router'
import authRoutes from '@/modules/auth/router'
import catalogsRoutes from '@/modules/catalogs/router'
import candidatesRoutes from '@/modules/candidates/router'
import companyRoutes from '@/modules/company/router'
import usersRoutes from '@/modules/users/router'
import EmployeeRoutes from '@/modules/employee/router'
import AssistanceRoutes from '@/modules/assistance/router'
import ContractsRoutes from '@/modules/contracts/router'
import store from '@/store'

Vue.use(VueRouter)

let baseRoutes = [
  {
    path: '*',
    name: 'index',
    redirect: {
      name: 'authLogin',
    },
  },
]

const routes = baseRoutes.concat(
  authRoutes,
  catalogsRoutes,
  candidatesRoutes,
  companyRoutes,
  usersRoutes,
  EmployeeRoutes,
  AssistanceRoutes,
  ContractsRoutes
)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requiresCompanyAdmin = to.matched.some(
    (record) => record.meta.requiresCompanyAdmin
  )
  const isLogged = store.getters['auth/isLogged']
  const isCompanyAdmin = store.getters['auth/isCompanyAdmin']
  const isEmployee = store.getters['auth/isEmployee']
  if (isLogged && to.path === '/auth/login') {
    if (!isEmployee) {
      next({ name: 'candidatesList' })
    } else {
      next({ name: 'assitance' })
    }
  }

  if (isLogged && to.path === '/auth/signup') {
    if (!isEmployee) {
      next({ name: 'candidatesList' })
    } else {
      next({ name: 'assitance' })
    }
  }

  // validate the views that can only be visited by company admins
  if (requiresCompanyAdmin && !isCompanyAdmin) {
    next({ name: 'authLogin' })
  }

  if (requiresAuth && !isLogged) {
    next({ name: 'authLogin' })
  }

  next()
})

export default router
