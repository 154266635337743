<template>
  <div class="candidates-container">
    <vs-row
      class="candidates-content"
      vs-align="flex-end"
      vs-type="flex"
      vs-justify="space-between"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-w="12">
        <FilterSideBar> </FilterSideBar>
        <vs-row
          class="candidates-content mt-8"
          vs-align="flex-start"
          vs-type="flex"
          vs-w="12"
        >
          <vs-table :data="contractList" class="w-full custom-centered-table">
            <template slot="thead">
              <vs-th class="custom-th">ID</vs-th>
              <vs-th class="custom-th">Tipo de Contrato</vs-th>
              <vs-th class="custom-th">Nombre del Contrato</vs-th>
              <vs-th class="custom-th">Fecha de Firma</vs-th>
              <vs-th class="custom-th">Link</vs-th>
              <vs-th class="custom-th">Estatus</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr v-for="item in data" :key="item.id">
                <vs-td class="custom-td">{{ item.id }}</vs-td>
                <vs-td class="custom-td">{{ item.documentType }}</vs-td>
                <vs-td class="custom-td">{{ item.documentName }}</vs-td>
                <vs-td v-if="!item.signDate" class="custom-td">{{
                  'No se ha firmado aún'
                }}</vs-td>
                <vs-td v-else class="custom-td">{{ item.signDate }}</vs-td>
                <vs-td class="custom-td">
                  <vs-button
                    class="mr-2"
                    color="primary"
                    @click="verContrato(item.document)"
                  >
                    Ver
                  </vs-button>
                </vs-td>
                <vs-td class="custom-td">
                  <template v-if="item.isSigned">
                    <span class="custom-firmado">Firmado</span>
                  </template>
                  <template v-else>
                    <vs-button
                      class="mr-2"
                      color="primary"
                      @click="firmarContrato(item)"
                    >
                      Firmar
                    </vs-button>
                  </template>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            v-model="currentPage"
            class="pagination-container mt-3"
            :total="totalEmployeePages"
          />
        </vs-row>
      </vs-col>
    </vs-row>
    <vue-fab
      size="big"
      icon="file_download"
      :scroll-auto-hide="false"
      @clickMainBtn="popup = true"
    />
  </div>
</template>

<script>
import FilterSideBar from '@/modules/contracts/components/FilterSideBar'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'ContractsListView',
  components: { FilterSideBar },
  data() {
    return {
      currentPage: 1,
      contractList: [], // Arreglo vacío para almacenar datos de contratos
      initialDate: null,
      totalEmployeePages: 1,
    }
  },
  computed: {
    ...mapState('auth', { user: 'user' }),
    ...mapState('company', { company: 'company' }),
  },
  mounted() {
    // Llama a la función para obtener los datos de contratos
    this.getInitialData()
  },
  methods: {
    ...mapActions('contracts', {
      getUserContracts: 'getUserContracts',
    }),

    async getInitialData() {
      const userProfileId = this.user.userprofile.id
      this.getUserContracts(userProfileId).then((res) =>
        this.contractList.push(...res)
      )
    },
    verContrato(link) {
      window.open(link, '_blank')
    },

    firmarContrato(contract) {
      const userId = this.user.userprofile.id
      this.$router
        .push({
          name: 'ContractsSignature',
          params: {
            contrato: contract,
            userProfileId: userId,
          },
        })
        .then(() => {
          this.active = false
        })
        .catch(() => {})
      // Function to handle signing a contract
      // Implement the logic to sign a contract here
    },
  },
}
</script>
<style lang="scss">
@import '~@/scss/_variables.scss';

/* Estilos adicionales para centrar la tabla */
.candidates-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Estilos adicionales para aumentar el tamaño de la tabla */
.custom-centered-table {
  width: 80%; /* Cambia el valor para ajustar el tamaño horizontal */
  margin-top: 30px;
}

/* Estilos adicionales para ajustar el tamaño de las columnas */
.custom-td {
  padding-left: 20px; /* Cambia el valor para ajustar el espacio horizontal */
  padding-right: 20px; /* Cambia el valor para ajustar el espacio horizontal */
}

.custom-th {
  padding-left: 20px; /* Cambia el valor para ajustar el espacio horizontal */
  padding-right: 20px; /* Cambia el valor para ajustar el espacio horizontal */
}

/* Estilos para el texto "Firmado" */
.custom-firmado {
  color: #00cc66; /* Verde */
  font-weight: bold;
}
</style>
