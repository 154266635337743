<template>
  <div class="candidates-container">
    <vs-row
      vs-align="flex-end"
      vs-type="flex"
      vs-justify="space-between"
      vs-w="12"
    >
      <vs-col vs-type="flex" vs-justify="center" vs-w="12">
        <FilterSideBar> </FilterSideBar>
        <vs-row
          class="candidates-content mt-8"
          vs-align="center"
          vs-type="flex"
          vs-justify="center"
          vs-w="12"
        >
          <!-- Canvas para la firma -->
          <canvas ref="signatureCanvas" class="signature-canvas"></canvas>
          <vs-row class="mt-1" vs-justify="center">
            <vs-button class="mr-2" color="primary" @click="clearSignature">
              Borrar
            </vs-button>
            <vs-button class="mr-2" color="success" @click="saveSignature">
              Guardar Firma
            </vs-button>
          </vs-row>
        </vs-row>
      </vs-col>
    </vs-row>
    <vue-fab
      size="big"
      icon="file_download"
      :scroll-auto-hide="false"
      @clickMainBtn="popup = true"
    />
  </div>
</template>

<script>
import FilterSideBar from '@/modules/contracts/components/FilterSideBar'
import SignaturePad from 'signature_pad'
import { mapState, mapActions } from 'vuex'
import utils from '../../../utils/utils'

export default {
  name: 'SignatureView',
  components: { FilterSideBar },
  data() {
    return {
      signaturePad: null,
    }
  },
  computed: {
    ...mapState('auth', {
      user: 'user',
    }),
    ...mapState('company', { company: 'company' }),
  },
  mounted() {
    // Inicializar el canvas una vez que el componente está montado
    this.initializeCanvas()
  },
  methods: {
    ...mapActions('contracts', ['saveSignatureAction']),
    initializeCanvas() {
      // Obtener la referencia al canvas
      const canvas = this.$refs.signatureCanvas

      // Ajustar el tamaño del canvas al doble
      canvas.width = canvas.offsetWidth * 2
      canvas.height = canvas.offsetHeight * 2

      // Configurar el objeto SignaturePad
      this.signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'white',
        penColor: 'black',
        minWidth: 2,
        maxWidth: 2,
      })
    },
    clearSignature() {
      // Limpiar el contenido del canvas
      this.signaturePad.clear()
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    saveSignature() {
      let userProfileId = this.$route.params.userProfileId
      let documentId = this.$route.params.contrato.id
      // Obtener la imagen en formato de Base64
      const dataURL = this.signaturePad.toDataURL('image/png')
      const file = this.dataURLtoFile(
        dataURL,
        `Firma-${this.user.name}-${this.user.lastName}-${this.user.secondLastName}-${this.company.name}-.jpg`
      )
      const formData = new FormData()
      formData.append('sign_picture', file)
      formData.append('user_profile', userProfileId)
      formData.append('legal_document', documentId)
      // Llamar a la acción saveSignatureAction para guardar la imagen en el servidor
      this.saveSignatureAction(formData)
        .then(() => {
          this.$vs.notify({
            title: 'Éxito',
            text: 'Firma guardada correctamente',
            color: 'success',
            position: 'bottom-center',
          })
          this.$router
            .push({
              name: 'Contracts',
            })
            .then(() => {
              this.active = false
            })
        })
        .catch((error) => {
          this.$vs.loading.close()
          let res = ''
          if (error.response) {
            res = utils.getErrorDetails(error.response.data.errors)
          } else {
            res = error.message
          }
          this.$vs.notify({
            color: 'danger',
            title: 'Ocurrió un problema al guardar la Firma',
            text: `${res}`,
            position: 'top-right',
          })
        })
    },
  },
}
</script>
<style lang="scss">
@import '~@/scss/_variables.scss';

.candidates-container {
  margin-bottom: 40px;
}

.split-container .split {
  height: calc(100vh - #{$navbar-height}) !important;
}

.split.split-horizontal .list-candidates-container {
  height: calc(100vh - #{$navbar-height} - 85px);
  overflow: auto;
}

.pagination-container .vs-col.vs-pagination--mb {
  justify-content: center !important;
}

.mx-datepicker-main {
  z-index: 40001 !important;
}

/* Estilos para el canvas */
.canvas-container {
  margin-top: 160px; /* Aumentar el margen superior al doble */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signature-canvas {
  border: 1px solid #ccc;
  cursor: crosshair;
}
</style>
