import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/modules/auth/store'
import company from '@/modules/company/store'
import catalogs from '@/modules/catalogs/store'
import candidates from '@/modules/candidates/store'
import users from '@/modules/users/store'
import employee from '@/modules/employee/store'
import assistance from '@/modules/assistance/store'
import contracts from '@/modules/contracts/store'

import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['auth', 'candidates', 'company'],
})
export default new Vuex.Store({
  state: {
    activeLoading: false,
    loadingPageMsg: '',
    errorMsg: '',
  },
  mutations: {
    setLoading(state, { isLoading, msg }) {
      state.activeLoading = isLoading
      state.loadingPageMsg = msg
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsg = errorMsg
    },
  },
  modules: {
    auth,
    company,
    catalogs,
    candidates,
    users,
    employee,
    assistance,
    contracts,
  },
  plugins: [vuexLocal.plugin],
})
