import axios from 'axios'

export async function saveSignatureAction({ commit }, data) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Subiendo Firma',
    },
    { root: true }
  )

  return new Promise((resolve, reject) => {
    let url = `/documents/document-signs/`
    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}

export async function getUserContracts({ commit }, userProfileId) {
  commit(
    'setLoading',
    {
      isLoading: true,
      msg: 'Obteniendo datos del empleado',
    },
    { root: true }
  )
  return new Promise((resolve, reject) => {
    const url = `/documents/user-documents/?user_profile=${userProfileId}`
    axios
      .get(url)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        // Finish loading page animation
        commit('setLoading', false, { root: true })
      })
  })
}
